import React from 'react';
import './App.css';
import RegisterList from './components/RegisterList';

function App() {
  return (
    <div className="container">
      <RegisterList />
    </div>
  );
}

export default App;
