import React, { useState, useEffect } from 'react';
import IssuesCheckboxList from './IssuesCheckboxList';
import RegisterVM from '../models/RegisterVM';

const initialFieldValues = new RegisterVM();
const defaultImageSrc = initialFieldValues.defaultImageSrc;

export default function Register(props) {

    const { addOrEdit, recordForEdit } = props;
    
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});

    const [kneeIssues, setKneeIssues] = useState(values.kneeIssues);
    const [lumberIssues, setLumberIssues] = useState(values.lumberIssues);
    const [sittingHours, setSittingHours] = useState(values.sittingHours);
    const [stressIssues, setStressIssues] = useState(values.stressIssues);
    const [eatingHabits, setEatingHabits] = useState(values.eatingHabits);
    const [cervicalIssues, setCervicalIssues] = useState(values.cervicalIssues);
    const [bonyDiformities, setBonyDiformities] = useState(values.bonyDiformities);
    const [pathalogicalIssues, setPathalogicalIssues] = useState(values.pathalogicalIssues);
    const [digestionStomachIssues, setDigestionStomachIssues] = useState(values.digestionStomachIssues);

    useEffect(() => {
        if (recordForEdit != null){
            resetFormData(recordForEdit);
        }
    }, [recordForEdit]);

    const resetForm = () => {
        resetFormData(initialFieldValues);
        document.getElementById('profileImage').value = null;
        document.getElementById('xrayFile').value = null;
        setErrors({});
    }

    const resetFormData = (data) => {
        console.log(data);
        setValues(data);
        setKneeIssues(data.kneeIssues);
        setLumberIssues(data.lumberIssues);
        setSittingHours(data.sittingHours);
        setStressIssues(data.stressIssues);
        setEatingHabits(data.eatingHabits);
        setCervicalIssues(data.cervicalIssues);
        setBonyDiformities(data.bonyDiformities);
        setPathalogicalIssues(data.pathalogicalIssues);
        setDigestionStomachIssues(data.digestionStomachIssues);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues((values) => ({ ...values, [name]: value }));
    };

    const showProfilePreview = (e) => {
        if (e.target.files && e.target.files[0]) {
            let profileImage = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (x) => {
                setValues({
                    ...values,
                    profileImage,
                    profileImageSrc: x.target.result
                });
            };
            reader.readAsDataURL(profileImage);
        } else {
            setValues({
                ...values,
                profileImage: null,
                profileImageSrc: defaultImageSrc
            });
        }
    }

    const showXrayPreview = (e) => {
        if (e.target.files && e.target.files[0]) {
            let xrayFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (x) => {
                setValues({
                    ...values,
                    xrayFile,
                    xrayFileSrc: x.target.result
                });
            };
            reader.readAsDataURL(xrayFile);
        } else {
            setValues({
                ...values,
                xrayFile: null,
                xrayFileSrc: defaultImageSrc
            });
        }
    }

    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.age = values.age === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every(x => x === true);
    }

    const handleFormSubmit = e => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData();
            formData.append("id", values.id);
            formData.append("name", values.name);
            formData.append("email", values.email);
            formData.append("mobile", values.mobile);
            formData.append("profileImage", values.profileImage);
            formData.append("xrayFile", values.xrayFile);

            values.kneeIssues = kneeIssues;
            values.lumberIssues = lumberIssues;
            values.sittingHours = sittingHours;
            values.stressIssues = stressIssues;
            values.eatingHabits = eatingHabits;
            values.cervicalIssues = cervicalIssues;
            values.bonyDiformities = bonyDiformities;
            values.pathalogicalIssues = pathalogicalIssues;
            values.digestionStomachIssues = digestionStomachIssues;

            if (values.xrayFile) values.xrayFileSrc = '';
            if (values.profileImage) values.profileImageSrc = '';
            formData.append("registration", JSON.stringify(values));
            addOrEdit(formData, resetForm);
        }
    }

    const handleBonyDiformities = (name, checked) => {
        setBonyDiformities((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleCervicalIssues = (name, checked) => {
        setCervicalIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleDigestionStomachIssues = (name, checked) => {
        setDigestionStomachIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleEatingHabits = (name, checked) => {
        setEatingHabits((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleKneeIssues = (name, checked) => {
        setKneeIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleLumberIssues = (name, checked) => {
        setLumberIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handlePathalogicalIssues = (name, checked) => {
        setPathalogicalIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleSittingHours = (name, checked) => {
        setSittingHours((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const handleStressIssues = (name, checked) => {
        setStressIssues((prevCheckboxes) =>
          prevCheckboxes.map((checkbox) =>
            checkbox.name === name ? { ...checkbox, value: checked } : checkbox
          )
        );
    };

    const applyErrorClass = field => ((field in errors && errors[field] === false) ? ' invalid-field' : '');
    return (
        <>
            <div className="container text-center">
            <h1 className="display-4 text-center">Add/Edit</h1>
            </div>
            <form noValidate onSubmit={handleFormSubmit}>
                <div className="card card-body">
                    <div className='form-row'>
                        <div className="col-md-8 mb-3">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input className={"form-control " + applyErrorClass('name')} placeholder="Name" name="name"
                                    value={values.name} onChange={handleInputChange} />
                            </div>
                            <div className='form-row'>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="mobile">Contact No.</label>
                                    <input className={"form-control " + applyErrorClass('mobile')} placeholder="Contact No." name="mobile"
                                        value={values.mobile} onChange={handleInputChange} />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="email">Email Id:</label>
                                    <input className={"form-control " + applyErrorClass('email')} placeholder="Email Id" name="email"
                                        value={values.email} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="age">Age</label>
                                    <input className={"form-control " + applyErrorClass('age')} placeholder="Age" name="age"
                                        value={values.age} onChange={handleInputChange} />
                                </div>
                                <div className="form-group col-md-6 mb-3">
                                    <label htmlFor="weight">Weight</label>
                                    <input className={"form-control " + applyErrorClass('weight')} placeholder="Weight" name="weight"
                                        value={values.weight} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="address">Address</label>
                                <textarea className={"form-control " + applyErrorClass('address')} placeholder="Address" name="address"
                                    value={values.address} onChange={handleInputChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="occupation">Occupation/Profession</label>
                                <input className="form-control" placeholder="Occupation" name="occupation"
                                    value={values.occupation} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="card card-body col-md-4 mb-3">
                            <img src={values.profileImageSrc} className="card-img-top" alt='Profile' />
                            <br/>
                            <div className="form-group">
                                <input type="file" accept="image/*" className={"form-control-file" + applyErrorClass('profileImageSrc')}
                                    onChange={showProfilePreview} id="profileImage" name="profileImage" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="currentMedications">Current Medications </label>
                        <input className="form-control" placeholder="Current Medications" name="currentMedications"
                            value={values.currentMedications} onChange={handleInputChange} />
                    </div>
                    
                    <div className='row'>Health Issues:</div>
                    <div className='form-row'>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={bonyDiformities} title={"Bony Diformities"} onChange={handleBonyDiformities} />
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={pathalogicalIssues} title={"Pathalogical Issues"} onChange={handlePathalogicalIssues} />
                        </div>
                    </div>
                    
                    <div className='form-row'>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={digestionStomachIssues} title={"Digestions/Stomach Issues"} onChange={handleDigestionStomachIssues} />
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={stressIssues} title={"Stress"} onChange={handleStressIssues}  />
                        </div>
                    </div>
                    
                    <div className='form-row'>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={eatingHabits} title={"Eating Habits"} onChange={handleEatingHabits} />
                        </div>
                        <div className="form-group col-md-6 mb-3">
                            <IssuesCheckboxList items={sittingHours} title={"Sitting Hours"} onChange={handleSittingHours} />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="otherHealthIssues">Other Health Issues (if any):</label>
                        <textarea className={"form-control"} placeholder="Other Health Issues (if any)" name="otherHealthIssues"
                            value={values.otherHealthIssues} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="xrayImpressions">Impressions of Xray / MRI</label>
                        <textarea className={"form-control"} placeholder="Impressions of Xray / MRI" name="xrayImpressions"
                            value={values.xrayImpressions} onChange={handleInputChange} />
                    </div>
                    <div className='form-row'>
                        <div className="form-group col-md-4">
                            <label htmlFor="xrayFile">Photo of an Xray / MRI</label>
                            <input type="file" accept="application/pdf,image/*" className={"form-control-file" + applyErrorClass('xrayFileSrc')}
                                onChange={showXrayPreview} id="xrayFile" name="xrayFile" />
                        </div>
                        {values.xrayFileSrc ?
                        <div className='form-group col-md-6'>
                            <a target='_blank' href={values.xrayFileSrc}>Click here for Xray / MRI File.</a>
                        </div> : ''}
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Notes: (Pernonal History Accident, Any recent surgery, Genetics)</label>
                        <textarea className={"form-control "} placeholder="Notes (Pernonal History Accident, Any recent surgery, Genetics)" name="notes"
                            value={values.notes} onChange={handleInputChange} />
                    </div>
                    
                    <div className='row'>Real Time Diagnosis :</div>
                    <div className='form-row'>
                        <div className="form-group col-md-4">
                            <IssuesCheckboxList items={cervicalIssues} title={"Cervical"} onChange={handleCervicalIssues} />
                        </div>
                        <div className="form-group col-md-4">
                            <IssuesCheckboxList items={lumberIssues} title={"Lumber"} onChange={handleLumberIssues} />
                        </div>
                        <div className="form-group col-md-4">
                            <IssuesCheckboxList items={kneeIssues} title={"Knee"} onChange={handleKneeIssues} />
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <button type="submit" className="btn btn-light">Submit</button>
                    </div>
                </div>
            </form>
        </>
    )
}
