export default class RegisterVM{
    constructor() {
        this.id = 0;
        this.name = '';
        this.email = '';
        this.mobile = '';
        this.age = '';
        this.weight = '';
        this.address = '';
        this.occupation = '';
        this.currentMedications = '';
        this.otherHealthIssues = '';
        this.xrayImpressions = '';

        this.bonyDiformities = this.getDefaultBonyDiformities();
        this.pathalogicalIssues = this.getDefaultPathalogicalIssues();
        this.digestionStomachIssues = this.getDefaultDigestionStomachIssues();
        this.stressIssues = this.getDefaultStressIssues();
        this.eatingHabits = this.getDefaultEatingHabits();
        this.sittingHours = this.getDefaultSittingHours();

        this.cervicalIssues = this.getDefaultDiagnosisIssues("cervicalIssues");
        this.lumberIssues = this.getDefaultDiagnosisIssues("lumberIssues");
        this.kneeIssues = this.getDefaultDiagnosisIssues("kneeIssues");

        this.defaultImageSrc = '/image_placeholder.png';
        this.profileImageSrc = this.defaultImageSrc;
        this.profileImage = null;
        this.xrayFileSrc = '';
        this.xrayFile = null;
    }

    getDefaultBonyDiformities(){
        return [
            {text: 'Cervical / Neck Pain', name: 'cervicalNeckPain', value: false},
            {text: 'Loss of Curvature', name: 'lossOfCurvature', value: false},
            {text: 'Spasm', name: 'spasm', value: false},
            {text: 'Shoulder Pain', name: 'shoulderPain', value: false},
            {text: 'Tennis Elbow', name: 'tennisElbow', value: false},
            {text: 'Sensation on Hands', name: 'sensationOnHands', value: false},
            {text: 'Lower Back Pain', name: 'lowerBackPain', value: false},
            {text: 'Degenerative Changes', name: 'degenerativeChanges', value: false},
            {text: 'Stenting of Lumber Spine', name: 'stentingOfLumberSpine', value: false},
            {text: 'Slip Disk', name: 'slipDisk', value: false},
            {text: 'Lysthesys', name: 'lysthesys', value: false},
            {text: 'Bulging', name: 'bulging', value: false},
            {text: 'Knee Pain', name: 'kneePain', value: false}
        ]
    }
    getDefaultPathalogicalIssues(){
        return [
            {text: 'BP', name: 'bp', value: false},
            {text: 'Diabetes', name: 'diabetes', value: false},
            {text: 'Hypertension', name: 'hypertension', value: false},
            {text: 'Palpitation', name: 'palpitation', value: false},
            {text: 'Hormonal Imbalance', name: 'hormonalImbalance', value: false},
            {text: 'PCOD', name: 'pcod', value: false},
            {text: 'Thyroid', name: 'thyroid', value: false},
            {text: 'Asthma Palpitation', name: 'asthmaPalpitation', value: false}
        ]
    }
    getDefaultDigestionStomachIssues(){
        return [
            {text: 'Gases', name: 'gases', value: false},
            {text: 'Bloating', name: 'bloating', value: false},
            {text: 'Acid Reflux', name: 'acidReflux', value: false},
            {text: 'Acidity', name: 'acidity', value: false},
            {text: 'Constipation', name: 'constipation', value: false}
        ]
    }
    getDefaultStressIssues(){
        return [
            {text: 'Work', name: 'work', value: false},
            {text: 'Personal', name: 'personal', value: false},
            {text: 'Profession Related', name: 'professionRelated', value: false}
        ]
    }
    getDefaultEatingHabits(){
        return [
            {text: 'Irregular Cycle', name: 'irregularCycle', value: false},
            {text: 'Eat outside food', name: 'eatOutsideFood', value: false},
            {text: 'More than 3 time a week outside food', name: 'moreThan3Time', value: false}
        ]
    }
    getDefaultSittingHours(){
        return [
            {text: 'More than 2 Hours', name: 'moreThan2Hours', value: false},
            {text: 'More than 4 Hours', name: 'moreThan4Hours', value: false},
            {text: 'More than 6-8 Hours', name: 'moreThan6Hours', value: false}
        ]
    }
    
    getDefaultDiagnosisIssues(id){
        return [
            {text: 'Flexion', name: id + '_flexion', value: false},
            {text: 'Extension', name: id + '_extension', value: false},
            {text: 'Rotation', name: id + '_rotation', value: false},
            {text: 'Up/Down Movement', name: id + '_upDownMovement', value: false}
        ]
    }
}