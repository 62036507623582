import React, { useState, useEffect } from 'react';
import Register from './Register';
import axios from "axios";

export default function RegisterList() {
    const [registerList, setRegisterList] = useState([]);
    const [recordForEdit, setRecordForEdit] = useState(null);

    useEffect(() => {
        refreshRegisterList();
    }, []);

    const registerAPI = (url = 'https://admin.pratishyoga.com/register.php') => {
        return {
            fetch: id => axios.get(url + '?id=' + id),
            fetchAll: () => axios.get(url),
            create: newRecord => axios.post(url, newRecord),
            update: (id, updatedRecord) => axios.post(url + '?id=' + id, updatedRecord),
            delete: id => axios.delete(url + '?id=' + id)
        };
    };

    function refreshRegisterList() {
        registerAPI().fetchAll()
            .then(res => {
                setRegisterList(res.data)
            })
            .catch(err => console.log(err));
    };

    function handleResponse(res, onSuccess){
        if (res.data["code"] === 200) {
            onSuccess();
            refreshRegisterList();
        } else {
            alert (res.data["errors"]);
        }
    }

    const addOrEdit = (formData, onSuccess) => {
        if (formData.get('id') === "0") {
            registerAPI().create(formData)
                .then(res => {
                    handleResponse(res, onSuccess);
                })
                .catch(err => console.log(err))
        } else {
            registerAPI().update(formData.get('id'), formData)
                .then(res => {
                    handleResponse(res, onSuccess);
                })
                .catch(err => console.log(err));
        }
    };
    const showRecordDetails = id => {
        registerAPI().fetch(id)
            .then(res => {
                var editData = res.data[0];
                if (editData.registration) {
                    console.log(editData);
                    var registrationData = JSON.parse(editData.registration);
                    registrationData.id = editData.id;
                    setRecordForEdit(registrationData);
                }
            })
            .catch(err => console.log(err));
    };

    const onDelete = (e, id) => {
        e.stopPropagation();
        if (window.confirm('Are you sure to delete this record?')) {
            registerAPI().delete(id)
                .then(res => refreshRegisterList())
                .catch(err => console.log(err));
        }
    };

    return (
        <div className='row'>
            <div className="col-md-12">
                <div className="form-group">
                    <input className="form-control" id="myInput" type="text" placeholder="Search.." />
                </div>
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody id="myTable">
                        { Array.isArray(registerList) ?
                            registerList.map( (data,key) =>
                                <tr key={key}>
                                    <td className='table-data'>{data.name}</td>
                                    <td className='table-data'>{data.mobile}</td>
                                    <td className='table-data'>{data.email}</td>
                                    <td>
                                        <button className="btn btn-light edit-button" onClick={() => { showRecordDetails(data.id) }}>
                                            <i className="far fa-edit"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-light delete-button" onClick={e => onDelete(e, parseInt(data.id))}>
                                            <i className="far fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                            ) : <tr></tr>
                        }
                    </tbody>
                </table>
                {/* <div dangerouslySetInnerHTML={{ __html: `<script>${script}</script>` }} /> */}
            </div>
            <div className="col-md-12">
                <Register
                    addOrEdit={addOrEdit}
                    recordForEdit={recordForEdit}
                />
            </div>
        </div>
    );
}
