import React, { useState, useEffect } from 'react';

export default function IssuesCheckboxList({ items, title, onChange }) {
  const [checkboxes, setCheckboxes] = useState(items);
  useEffect(() => {
    if (items != null){
      setCheckboxes(items);
    }}, [items]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, value: checked } : checkbox
      )
    );
    onChange(name, checked);
  };
  
  if (items && items.length !== 0) {
    return (
      <>
        <label>{title}</label>
        {checkboxes.map(({ text, name, value }) => (
          <div key={name} className="form-check marginleft50">
              <input className={"form-check-input"} id={name} name={name}
                  checked={value} type="checkbox" onChange={handleCheckboxChange} />
              <label className="form-check-label" htmlFor={name}>{text}</label>
          </div>
        ))}
      </>
    );
  }
  else{
    return <div></div>;
  }
}